import React, { useCallback, useState, useLayoutEffect } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import { Button, Fade } from '@material-ui/core';
import { TableContainer, PageContainer } from '../../../../../app/styles';

import { IProfilePermission } from '../../../../../utils/interfaces';

import { Table } from '../../../../../components/Tools/Table';
import TableSelectBox from '../../../../../components/Tools/SelectBox';

import { savePermissionsRequest } from '../../api/profilePermissions.api';

import { IProfilePermissionsTableProps } from '../../types';

const ProfilePermissionsTable: React.FC<IProfilePermissionsTableProps> = ({
  rows,
  paramsData,
  isAdmin,
}) => {
  const [rowsState, setRowsState] = useState<IProfilePermission[]>(rows);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };

  const handleTogglePermission = useCallback(
    (systemCode: number) => {
      rowsState.map(p => {
        if (p.codigoSistemaMenu === systemCode) {
          p.permissao = !p.permissao;
        }
        return p;
      });
    },
    [rowsState]
  );

  const handleToggleEdit = useCallback(
    (systemCode: number) => {
      rowsState.map(p => {
        if (p.codigoSistemaMenu === systemCode) {
          p.editar = !p.editar;
        }
        return p;
      });
    },
    [rowsState]
  );

  const handleToggleExport = useCallback(
    (systemCode: number) => {
      rowsState.map(p => {
        if (p.codigoSistemaMenu === systemCode) {
          p.exportar = !p.exportar;
        }
        return p;
      });
    },
    [rowsState]
  );

  const handleToggleIntegrate = useCallback(
    (systemCode: number) => {
      rowsState.map(p => {
        if (p.codigoSistemaMenu === systemCode) {
          p.integrar = !p.integrar;
        }
        return p;
      });
    },
    [rowsState]
  );

  const handleSavePermissions = useCallback(async () => {
    enqueueSnackbar(`${traductorMsg('screens.profile.saving')}...`, {
      variant: 'info',
    });

    await savePermissionsRequest(clientId, rowsState, paramsData, isAdmin)
      .then(() => {
        enqueueSnackbar(
          `${traductorMsg('screens.profile.permissionsSaving')} !`,
          {
            variant: 'success',
          }
        );
      })
      .catch(err => {
        enqueueSnackbar(
          `${traductorMsg('screens.user.error')}: ${err.message}`,
          {
            variant: 'error',
          }
        );
      });
  }, [enqueueSnackbar, clientId, rowsState, paramsData, isAdmin]);

  useLayoutEffect(() => {
    setRowsState(rows);
  }, [rows]);

  return (
    <Fade in timeout={1000} unmountOnExit>
      <PageContainer withOutPadding>
        <TableContainer>
          <Table<IProfilePermission>
            rows={rowsState}
            getRows={[
              {
                onClick: () => handleSavePermissions(),
                renderItem: () => (
                  <Button
                    className="secundaryButton"
                    type="button"
                    variant="contained"
                    style={{ background: '#46BE7D', color: '#fff' }}
                  >
                    {t('screens.profile.save')}
                  </Button>
                ),
              },
            ]}
            columns={[
              {
                title: String(t('screens.profileTable.permissions')),
                type: 'string',
                orderable: true,
                props: ['permissao'],
                renderItem: row => (
                  <TableSelectBox
                    select={Boolean(row.permissao)}
                    onClick={() => {
                      handleTogglePermission(row.codigoSistemaMenu);
                    }}
                  />
                ),
              },
              {
                title: String(t('screens.profile.menuDescription')),
                type: 'stringCenter',
                orderable: true,
                props: ['descricaoMenu'],
              },
              {
                title: String(t('screens.profileTable.edit')),
                type: undefined,
                orderable: true,
                props: ['editar'],
                renderItem: row => (
                  <>
                    {row.codigoMenuPai && (
                      <TableSelectBox
                        select={Boolean(row.editar)}
                        onClick={() => {
                          handleToggleEdit(row.codigoSistemaMenu);
                        }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: String(t('components.table.export')),
                type: 'string',
                orderable: true,
                props: ['exportar'],
                renderItem: row => (
                  <>
                    {row.codigoMenuPai && (
                      <TableSelectBox
                        select={Boolean(row.exportar)}
                        onClick={() => {
                          handleToggleExport(row.codigoSistemaMenu);
                        }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: String(t('screens.profile.integrate')),
                type: 'string',
                orderable: true,
                props: ['integrar'],
                renderItem: row => (
                  <>
                    {row.codigoMenuPai && (
                      <TableSelectBox
                        select={Boolean(row.integrar)}
                        onClick={() => {
                          handleToggleIntegrate(row.codigoSistemaMenu);
                        }}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
        </TableContainer>
      </PageContainer>
    </Fade>
  );
};

export default ProfilePermissionsTable;
