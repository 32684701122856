import styled from 'styled-components';

export const ClientsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;

  h2 {
    font-weight: 500;
    color: ${props => props.theme.colors.title_color};
    font-size: clamp(1.5rem, 25px, 5vmin);
    text-align: center;
  }
`;

export const ClientsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.footer_text};

  @media screen and (max-width: 600px) {
    justify-content: center;
    gap: 12px;
  }
`;

export const ButtonsContainer = styled.div`
  .clientsButtons {
    min-width: 100px;
    padding: 16px;
    height: 130px;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    background: transparent;
    border: 2px dashed ${props => props.theme.colors.footer_text};
    color: ${props => props.theme.colors.footer_text};
    transition: 0.25s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: none;
      color: #0095e8 !important;
      border: 2px dashed #0095e8;
      background: #0095e8;
      background-color: ${props =>
        props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};

      svg {
        transition: 0.25s ease-in-out;

        path {
          fill: ${props => props.theme.colors.main_blue_clr} !important;
        }
      }
    }
  }
`;
