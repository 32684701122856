/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { CircularProgress, Button } from '@material-ui/core';

import { AdmIcon } from '../AdmIcon';

import { useAuth } from '../../../hooks/Auth';

import { ClientsBox, ButtonsContainer } from '../styles';

import api from '../../../services/api';

import { IClient } from '../../../utils/interfaces';

import { ISystem } from '../types';

const Clientes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const codeClient = localStorage.getItem('@pdamodules::codigoCliente');
  const isLead = localStorage.getItem('@pdamodules::lider');

  const getSystens = async (codeProfile: number) => {
    setIsLoading(true);

    const dataAPI = {
      codigoPerfil: codeProfile,
      tipo: 'A',
    };

    try {
      const res = await api.patch('Sistema', { ...dataAPI });

      if (!res.data.length) {
        enqueueSnackbar('Não foi encontrado nenhum sistema', {
          variant: 'warning',
        });
        return undefined;
      }

      const response: ISystem[] = res.data;

      const filterAdminSystem = response.find(s => s.sigla === 'ADM');
      return filterAdminSystem;
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao buscar os sistemas', {
        variant: 'error',
      });
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const getClients = useCallback(async () => {
    setIsLoading(true);

    await api
      .get('Usuario/Autenticado')
      .then(res => {
        if (res.data?.clientes.length > 1) {
          const admClient: IClient = {
            codigoCliente: Number(codeClient),
            descricaoCliente: 'Administração',
            codigoSistema: Number(res.data?.codigoPerfil),
            codigoConta: 33,
          };

          const clientsData =
            isLead === 'true'
              ? [admClient, ...res.data.clientes]
              : [...res.data.clientes];
          setClients(clientsData);

          return;
        }

        const { descricaoCliente, codigoCliente } = res.data.clientes[0];

        if (codigoCliente) {
          localStorage.removeItem('@pdamodules::codigoCliente');
          localStorage.removeItem('@pdamodules::cliente');

          localStorage.setItem(
            '@pdamodules::codigoCliente',
            JSON.stringify(codigoCliente)
          );
          localStorage.setItem(
            '@pdamodules::cliente',
            JSON.stringify(descricaoCliente)
          );
          history.push('/choose-module');
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          signOut();
          return;
        }
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [codeClient, isLead, history, enqueueSnackbar, signOut]);

  const handleClientClick = async (client: IClient) => {
    if (client.descricaoCliente === 'Administração') {
      const system = await getSystens(clients[0].codigoSistema);
      localStorage.setItem('@pdamodules::codigoCliente', '0');
      localStorage.setItem(
        '@pdamodules::codigoSistema',
        String(system?.codigoSistema)
      );
      history.push('/Profile');
      return;
    }
    if (client.codigoCliente) {
      setIsLoading(true);

      localStorage.setItem(
        '@pdamodules::codigoCliente',
        JSON.stringify(client.codigoCliente)
      );
      localStorage.setItem(
        '@pdamodules::cliente',
        JSON.stringify(client.descricaoCliente)
      );

      history.push('/choose-module');
    }
  };

  useLayoutEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <ClientsBox>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : clients.length ? (
        clients.map((client, index) => (
          <ButtonsContainer>
            <Button
              key={index}
              className="clientsButtons"
              type="button"
              variant="contained"
              onClick={() => handleClientClick(client)}
              style={{
                display: 'block',
                textAlign: 'center',
              }}
            >
              {client.descricaoCliente === 'Administração' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <AdmIcon />
                  <span>{client.descricaoCliente}</span>
                </div>
              ) : (
                client.descricaoCliente
              )}
            </Button>
          </ButtonsContainer>
        ))
      ) : (
        <span>Não há nada</span>
      )}
    </ClientsBox>
  );
};

export default Clientes;
