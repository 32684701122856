/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../../../../services/api';

import { transformToSelect } from '../../../../utils/toSelect';

import { IProfilePermission } from '../../../../utils/interfaces';

import { ISearchParam } from '../types';

export const getOptionsAPIs = async (clientId: number, profileCode: string) => {
  const requestData = {
    codigoCliente: clientId,
    codigoPerfil: Number(profileCode),
    tipo: 'A',
  };

  const [systemsOptions, clientsOptions] = await Promise.all([
    api.patch('/Sistema', { ...requestData }),
    api.get(`/Cliente/ClienteConta`),
  ]);

  const formatedSystemData = transformToSelect(systemsOptions.data);

  const formatedClientData = clientsOptions.data.map(
    (c: { nome: string; codigoCliente: number }) => {
      return {
        label: c.nome,
        value: c.codigoCliente,
      };
    }
  );

  return [formatedSystemData, formatedClientData];
};

export const searchBoxRequest = async (
  clientId: number,
  profileCode: string,
  data: ISearchParam
) => {
  const requestData = {
    CodigoCliente: data.client || undefined,
    codigoPerfil: Number(profileCode),
    codigoSistema: data.systems,
  };

  return api.patch<IProfilePermission[]>('/PerfilAcesso', { ...requestData });
};

export const savePermissionsRequest = async (
  clientId: number,
  permissions: IProfilePermission[],
  paramsData: ISearchParam,
  isAdmin: boolean
) => {
  const verifySystem = !isAdmin && paramsData ? paramsData.client : undefined;
  const modifiedItemList = permissions.map(item => ({
    codigoSistemaMenu: item.codigoSistemaMenu,
    codigoSistemaPerfil: item.codigoSistemaPerfil,
    descricaoMenu: item.descricaoMenu,
    codigoMenuPai: item.codigoMenuPai,
    permissao: item.permissao,
    editar: item.editar,
    exportar: item.exportar,
    integrar: item.integrar,
  }));

  const requestData = {
    codigoCliente: verifySystem,
    menus: modifiedItemList,
  };

  return api.post('/PerfilAcesso', { ...requestData });
};
